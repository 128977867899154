import React, { useEffect, useRef } from "react";
import cx from "classnames";

import "./Tabs.scss";

export const TabItem = ({
    value,
    current,
    className,
    moveScroll,
    children,
    ...rest
}) => {
    const tabRef = useRef();

    useEffect(() => {
        if (current === value) {
            moveScroll(tabRef.current.offsetLeft + tabRef.current.clientWidth);
        }
    }, [current, value]);

    return (
        <li
            className={cx("rs-tab-item", className, {
                active: current === value
            })}
            ref={tabRef}
            {...rest}
        >
            {children}
        </li>
    );
};

const Tabs = ({ current, className, children }) => {
    const tabsRef = useRef();

    const onMoveScroll = xPosition => {
        if (tabsRef.current.parentNode) {
            if (tabsRef.current.parentNode.clientWidth > xPosition) {
                tabsRef.current.parentNode.scrollTo(0, 0);
            } else {
                tabsRef.current.parentNode.scrollTo(xPosition, 0);
            }
        }
    };

    return (
        <ul className={cx("rs-tabs", className)} ref={tabsRef}>
            {React.Children.map(children, child =>
                child
                    ? React.cloneElement(child, {
                          current,
                          moveScroll: onMoveScroll
                      })
                    : null
            )}
        </ul>
    );
};

export default Tabs;
