import React from "react";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import Button from "../Button";

import "./ConfirmModal.scss";

const ConfirmModal = ({
    cancelText = "취소",
    confirmText = "확인",
    onCancel,
    onConfirm,
    className,
    children,
    ...rest
}) => (
    <ModalWrapper>
        <ModalBackground>
            <div className="rs-confirm-modal">
                <div
                    className={`rs-confirm-modal-content ${className}`}
                    {...rest}
                >
                    {children}
                </div>
                <div className="rs-confirm-modal-btn-area">
                    <Button
                        onClick={onCancel}
                        className="theme3 size-l m-size-l"
                    >
                        {cancelText || "아니요"}
                    </Button>
                    <Button
                        className="theme1 size-l m-size-l"
                        onClick={onConfirm}
                    >
                        {confirmText || "예"}
                    </Button>
                </div>
            </div>
        </ModalBackground>
    </ModalWrapper>
);

export default ConfirmModal;
