export const Color = {
    black: "#000000",
    white: "#ffffff",
    gray0: "#f7f7fa",
    gray1: "#e6e8ed",
    gray2: "#9da1a8",
    gray3: "#52565d",
    gray4: "#33363b",
    gray5: "#202225",

    primaryColor: "#0067ff",
    secondaryColor: "#003cff",
    tertiaryColor: "#5a00ff",

    mainColor: "#0067ff",
    activeColor: "#003cff",
    activeColorLight: "#e5f0ff",
    activeColorGray: "#f7f7fa",

    blueLightest: "#f7faff",

    redLighter: "#fcf1f0",
    blueLighter: "#eef5ff",

    yellowLight: "#ffe368",
    orangeLight: "#ff9d5a",
    redLight: "#eb8276",
    greenLight: "#8bdb8e",
    blueLight: "#5e9fff",
    purpleLight: "#c189f9",

    yellow: "#ffd400",
    orange: "#ff7326",
    red: "#fa4238",
    green: "#02c639",
    blue: "#0067ff",
    purple: "#9727f1",

    yellowDark: "#ffbf00",
    orangeDark: "#ff5300",
    redDark: "#d41100",
    greenDark: "#01a31d",
    blueDark: "#003ffc",
    purpleDark: "#7b00e0",

    Instagram: "#c32aa3",
    NaverBlog: "#02c639",
    Facebook: "#0d33a2",
    Youtube: "#fa4238",
    KakaoStory: "#fbc800",
    NaverPost: "#01beff",
    NaverCafe: "#0d33a2",
    AppReview: "#15ceC0"
};

export const Font = {
    weight: {
        thin: 300,
        normal: 400,
        medium: 500,
        bold: 700
    }
};

export const rem = val => `${((val * 1) / 16) * 1}rem`;
export const mobile = val => `${(val / 720) * 100}vw`;
