import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./InputWrapper.scss";

const InputWrapper = ({
    disabled,
    search,
    remove,
    addonBefore,
    addonAfter,
    prefix,
    suffix,
    className,
    children,
    ...rest
}) => (
    <div
        className={cx("rs-input-wrapper input-wrapper", className, {
            disabled,
            "has-prefix": !!prefix,
            "has-suffix": !!suffix
        })}
        {...rest}
    >
        {children}
        {addonBefore && (
            <div className="rs-input-addon-before">{addonBefore}</div>
        )}
        {prefix && <div className="rs-input-prefix">{prefix}</div>}
        {suffix && <div className="rs-input-suffix">{suffix}</div>}
        {(!!search || !!remove) && (
            <div className="rs-input-buttons">
                {search && (
                    <button
                        type="button"
                        className="rs-input-search-btn"
                        onClick={search}
                        disabled={disabled}
                    >
                        <IconFont className="icon_search">검색</IconFont>
                    </button>
                )}
                {remove && (
                    <button
                        type="button"
                        className="rs-input-delete-btn"
                        onClick={remove}
                        disabled={disabled}
                    >
                        <IconFont className="icon_close_circle_fill">
                            텍스트 제거
                        </IconFont>
                    </button>
                )}
            </div>
        )}
        {addonAfter && <div className="rs-input-addon-after">{addonAfter}</div>}
    </div>
);

export default InputWrapper;
