import React, { Component, forwardRef } from "react";
import cx from "classnames";
import Spin from "../Spin";

import "./Button.scss";

class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }

    onClickButton = async (e, onClick) => {
        if (this.state.disabled) return;
        if (this.props.loading) return;
        const focusElement = document.activeElement;
        if (focusElement) {
            focusElement.blur();
        }
        if (onClick) {
            this.setState({ disabled: true });
            await onClick(e);
            this.setState({ disabled: false });
        }
    };

    render() {
        const {
            className,
            loading,
            needLoading,
            disabled,
            onClick,
            children,
            ...rest
        } = this.props;
        return (
            <button
                ref={this.props.innerRef}
                type="button"
                className={cx("rs-button", className)}
                disabled={disabled}
                onClick={e => this.onClickButton(e, onClick)}
                {...rest}
            >
                {loading || (needLoading && this.state.disabled) ? (
                    <Spin />
                ) : (
                    children
                )}
            </button>
        );
    }
}

export default React.forwardRef((props, ref) => (
    <Button innerRef={ref} {...props} />
));
