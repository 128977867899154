import React from "react";

import "./ProductCard.scss";

const ProductCard = ({ title }) => {
    if (!title) return new Error("title를 입력해주세요.");

    return (
        <div className="rs-card-item">
            <h2>{title}</h2>
            <p>{title}</p>
        </div>
    );
};

export default ProductCard;
