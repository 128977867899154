import React from "react";
import cx from "classnames";
import { isMobile } from "../utils/Utils";
import ScrollUpModal from "../ScrollUpModal";

import "./SelectModal.scss";

const SelectModal = ({
    value,
    elements,
    setStatus,
    className,
    onClick,
    children,
    ...rest
}) =>
    isMobile() && (
        <ScrollUpModal
            closeModal={() => setStatus(false)}
            className={cx("rs-select-modal", className)}
            {...rest}
        >
            <div className="rs-select-modal-select-wrapper">{children}</div>
        </ScrollUpModal>
    );

export default SelectModal;
