import React from "react";
import ReactQuill from "react-quill";
import { Color } from "../theme/Theme";

import "react-quill/dist/quill.snow.css";
import "./Editor.scss";

const editorKey = Math.random()
    .toString(36)
    .substr(2, 11);

const Editor = ({ noImage, ...rest }) => {
    const modules = {
        toolbar: [
            [{ header: [3, false] }],
            ["bold", "italic", "underline", "strike"],
            [
                {
                    color: [
                        Color.red,
                        Color.orange,
                        Color.yellow,
                        Color.green,
                        Color.blue,
                        Color.purple,
                        Color.gray3,
                        Color.redDark,
                        Color.orangeDark,
                        Color.yellowDark,
                        Color.greenDark,
                        Color.blueDark,
                        Color.purpleDark,
                        Color.gray5
                    ]
                },
                {
                    background: [
                        `${Color.redLight}35`,
                        `${Color.orangeLight}35`,
                        `${Color.yellowLight}35`,
                        `${Color.greenLight}35`,
                        `${Color.blueLight}35`,
                        `${Color.purpleLight}35`,
                        Color.gray1,
                        `${Color.redLight}55`,
                        `${Color.orangeLight}55`,
                        `${Color.yellowLight}55`,
                        `${Color.greenLight}55`,
                        `${Color.blueLight}55`,
                        `${Color.purpleLight}55`,
                        Color.gray2,
                        Color.redLight,
                        Color.orangeLight,
                        Color.yellowLight,
                        Color.greenLight,
                        Color.blueLight,
                        Color.purpleLight,
                        false
                    ]
                }
            ],
            [{ list: "ordered" }, { list: "bullet" }],
            ["blockquote", "code-block"],
            noImage ? ["link"] : ["link", "image"]
        ]
    };

    return (
        <React.Fragment>
            <ReactQuill
                id={`rs-react-quill-editor-${editorKey}`}
                className="rs-react-quill-editor"
                modules={modules}
                {...rest}
            />
        </React.Fragment>
    );
};

export default Editor;
