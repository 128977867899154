import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Notification.scss";

const Notification = ({ children, className, close, ...rest }) => (
    <div className={cx("rs-notification", className)} {...rest}>
        <div className="rs-notification-content">
            <button
                type="button"
                className="rs-notification-close-btn"
                onClick={close}
            >
                <IconFont icon="icon_close">닫기</IconFont>
            </button>
            {children}
        </div>
    </div>
);

export default Notification;
