import React, { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import { isMobile as checkMobile } from "../utils/Utils";
import IconFont from "../IconFont";
import ScrollUpModal from "../ScrollUpModal";

import "./MoreMenu.scss";

export const MoreMenuItem = ({ className, children, ...rest }) => (
    <li className={cx("rs-more-menu-item", className)}>
        <button type="button" {...rest}>
            {children}
        </button>
    </li>
);

const MoreMenu = ({
    title = "더보기",
    icon = "icon_more_vertical",
    direction = "topRight",
    className,
    children
}) => {
    const isMobile = checkMobile();
    const [status, setStatus] = useState(false);

    const closeMenu = useCallback(() => {
        setStatus(false);
    }, []);

    useEffect(() => {
        if (status) {
            document.addEventListener("click", closeMenu);
        } else {
            document.removeEventListener("click", closeMenu);
        }
    }, [status]);

    return (
        <div className={cx("rs-more-menu", className, { active: status })}>
            <button
                type="button"
                className="rs-more-menu-btn"
                onClick={() => setStatus(true)}
            >
                <IconFont icon={icon}>더보기</IconFont>
            </button>
            {status && (
                <React.Fragment>
                    {isMobile ? (
                        <ScrollUpModal
                            id="rs-more-menu-scroll-up-modal"
                            title={title || "더보기"}
                            closeModal={() => setStatus(false)}
                        >
                            <ul className="rs-more-menu-items">{children}</ul>
                        </ScrollUpModal>
                    ) : (
                        <ul className={cx("rs-more-menu-items", direction)}>
                            {children}
                        </ul>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default MoreMenu;
