import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./CautionList.scss";

const CautionList = ({ title = "꼭 알아두세요!", items, className }) =>
    items && items.length ? (
        <div className={cx("rs-caution-area", className)}>
            {title && (
                <p className="rs-caution-title">
                    <IconFont icon="icon_caution_circle" />
                    {title}
                </p>
            )}
            {items.map((item, index) => (
                <p className="rs-caution-text" key={index}>
                    <span>{item}</span>
                </p>
            ))}
        </div>
    ) : null;

export default CautionList;
