import React, { useState, forwardRef, useImperativeHandle } from "react";
import ConfirmModal from "../ConfirmModal";

// 새로 사용되지 않는 컴포넌트
const CheckConfirmModal = forwardRef(
    ({ cancelText = "취소", confirmText = "확인", children, ...rest }, ref) => {
        const [showStatus, setShowStatus] = useState(false);
        const [mainText, setMainText] = useState("");
        const [subText, setSubText] = useState("");
        const [config, setConfig] = useState({});

        const resetState = () => {
            setShowStatus(false);
            setConfig({});
        };

        const onCancel = () => {
            if (config.callback) {
                config.callback(false);
            }
            resetState();
        };

        const onConfirm = () => {
            if (config.callback) {
                config.callback(true);
            }
            resetState();
        };

        // 상위 컴포넌트에서 호출가능한 함수
        useImperativeHandle(ref, () => ({
            check(_subText, _mainText) {
                setShowStatus(true);
                setMainText(_mainText);
                setSubText(_subText);
                return new Promise(res => setConfig({ callback: res }));
            }
        }));

        return showStatus ? (
            <ConfirmModal
                onConfirm={onConfirm}
                onCancel={onCancel}
                cancelText={cancelText}
                confirmText={confirmText}
                {...rest}
            >
                {mainText && (
                    <div className="rs-confirm-modal-main-text">{mainText}</div>
                )}
                {subText}
            </ConfirmModal>
        ) : (
            false
        );
    }
);

export default CheckConfirmModal;
