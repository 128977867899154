import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Radio.scss";

const Radio = ({ className, value, isActive, onClick, children, ...rest }) => (
    <label
        className={cx("rs-radio-item", className, {
            active: isActive
        })}
        {...rest}
    >
        <input
            type="radio"
            value={value}
            defaultChecked={isActive}
            onClick={() => onClick && onClick(value)}
        />
        <IconFont icon={isActive ? "icon_radio_on" : "icon_radio_off"} />
        <div className="rs-radio-item-text">{children}</div>
    </label>
);

export default Radio;
