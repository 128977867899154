import React, { useState, forwardRef, useImperativeHandle } from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import IconFont from "../IconFont";

import "./StandardModal.scss";

const StandardModal = forwardRef(
    (
        {
            align = "left",
            closeModal,
            autoClose = true,
            modalTitle = false,
            modalTitleBtn,
            modalFooter,
            className,
            children,
            ...rest
        },
        ref
    ) => {
        const [status, setStatus] = useState(true);
        const onClickCloseBtn = () => {
            if (closeModal) {
                if (autoClose) {
                    setStatus(false);
                }
                setTimeout(() => closeModal(), 100);
            }
        };

        // 상위 컴포넌트에서 호출가능한 함수
        useImperativeHandle(ref, () => ({
            onClose() {
                onClickCloseBtn();
            }
        }));

        return (
            <ModalWrapper>
                <ModalBackground>
                    <div
                        className={cx("rs-standard-modal", align, className, {
                            "has-title": !!modalTitle,
                            "has-footer": !!modalFooter,
                            active: status
                        })}
                        {...rest}
                    >
                        <div
                            className={cx("rs-standard-modal-header", {
                                "has-content": !!modalTitle
                            })}
                        >
                            <div
                                className="rs-standard-modal-close-btn"
                                onClick={() => onClickCloseBtn()}
                                onKeyDown={e =>
                                    e.keyCode === 13 && onClickCloseBtn()
                                }
                                tabIndex={0}
                                role="button"
                            >
                                <IconFont icon="icon_close" />
                            </div>
                            {modalTitle && (
                                <div className="rs-standard-modal-title-area">
                                    <div className="rs-standard-modal-title-custom-btn">
                                        {modalTitleBtn}
                                    </div>
                                    <div className="rs-standard-modal-title">
                                        {modalTitle}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="rs-standard-modal-scroll-area">
                            <div className="rs-standard-modal-content">
                                {children}
                            </div>
                        </div>

                        <div
                            className={cx("rs-standard-modal-footer", {
                                "has-content": !!modalFooter
                            })}
                        >
                            {modalFooter}
                        </div>
                    </div>
                </ModalBackground>
            </ModalWrapper>
        );
    }
);

export default StandardModal;
