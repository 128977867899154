import React, { useState } from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import IconFont from "../IconFont";

import "./ScrollUpModal.scss";

const ScrollUpModal = ({
    closeModal,
    title,
    footer,
    closeBtn,
    className,
    children,
    ...rest
}) => {
    const [status, setStatus] = useState(true);

    const onClose = () => {
        setStatus(false);
        setTimeout(() => closeModal(), 400);
    };

    return (
        <ModalWrapper>
            <ModalBackground onClick={onClose} noFullHeight>
                <div
                    className={cx("rs-scroll-up-modal", className, {
                        active: status
                    })}
                    {...rest}
                >
                    <button
                        type="button"
                        className="rs-scroll-up-modal-close-btn"
                        tabIndex={0}
                        onClick={onClose}
                    >
                        <IconFont icon="icon_close">닫기</IconFont>
                    </button>
                    {title && (
                        <div className="rs-scroll-up-modal-title-area">
                            {title}
                        </div>
                    )}
                    <div className="rs-scroll-up-modal-scroll-wrapper">
                        <div className="rs-scroll-up-modal-content-area">
                            {children}
                        </div>
                    </div>
                    {!!footer && (
                        <div className="rs-scroll-up-modal-footer-area">
                            {footer}
                        </div>
                    )}
                </div>
            </ModalBackground>
        </ModalWrapper>
    );
};
export default ScrollUpModal;
