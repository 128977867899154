import React, { useState, forwardRef, useImperativeHandle } from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import IconFont from "../IconFont";

import "./Drawer.scss";

const Drawer = forwardRef(
    (
        {
            align = "center",
            onClose,
            autoClose = true,
            title = false,
            titleBtn,
            footer,
            className,
            backgroundClassName,
            children,
            ...rest
        },
        ref
    ) => {
        const [status, setStatus] = useState(true);
        const onClickCloseBtn = () => {
            if (onClose) {
                if (autoClose) {
                    setStatus(false);
                }
                setTimeout(() => onClose(), 100);
            }
        };

        // 상위 컴포넌트에서 호출가능한 함수
        useImperativeHandle(ref, () => ({
            onClose() {
                onClickCloseBtn();
            }
        }));

        return (
            <ModalWrapper>
                <ModalBackground
                    className={`rs-drawer-background ${backgroundClassName}`}
                >
                    <div
                        className={cx("rs-drawer", align, className, {
                            "has-title": !!title,
                            "has-footer": !!footer,
                            active: status
                        })}
                        {...rest}
                    >
                        <div
                            className={cx("rs-drawer-header", {
                                "has-content": !!title
                            })}
                        >
                            <div
                                className="rs-drawer-close-btn"
                                onClick={() => onClickCloseBtn()}
                                onKeyDown={e =>
                                    e.keyCode === 13 && onClickCloseBtn()
                                }
                                tabIndex={0}
                                role="button"
                            >
                                <IconFont icon="icon_line_arrow_left" />
                            </div>
                            {title && (
                                <div className="rs-drawer-title-area">
                                    <div className="rs-drawer-title-custom-btn">
                                        {titleBtn}
                                    </div>
                                    <div className="rs-drawer-title">
                                        {title}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="rs-drawer-scroll-area">
                            <div className="rs-drawer-content">{children}</div>
                        </div>

                        <div
                            className={cx("rs-drawer-footer", {
                                "has-content": !!footer
                            })}
                        >
                            {footer}
                        </div>
                    </div>
                </ModalBackground>
            </ModalWrapper>
        );
    }
);

export default Drawer;
