import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    forwardRef
} from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Bubble.scss";

// type = ["click", "hover", "keep"]
const BubbleContent = forwardRef(
    (
        {
            isShow,
            type,
            direction,
            vertex,
            children,
            className,
            onClose,
            style,
            ...rest
        },
        ref
    ) => {
        const [status, setStatus] = useState(isShow);

        const componentClassName = cx("rs-bubble", `${type}-type`, className);
        const contentClassName = cx("rs-bubble-content", direction, {
            active: isShow,
            vertex
        });

        useEffect(() => {
            if (isShow) {
                setStatus(true);
            } else {
                if (type === "keep") {
                    setTimeout(() => setStatus(false), 300);
                } else {
                    setStatus(false);
                }
            }
        }, [isShow]);

        return status && children ? (
            <div className={componentClassName} ref={ref} {...rest}>
                <div className={contentClassName}>
                    {children}
                    {type === "click" && (
                        <IconFont icon="icon_close" onClick={onClose}>
                            닫기
                        </IconFont>
                    )}
                </div>
            </div>
        ) : null;
    }
);

const Bubble = ({
    status = true,
    type = "keep",
    direction = "bottom",
    duration = 0,
    vertex = true,
    onClose,
    ...rest
}) => {
    const bubbleRef = useRef();
    const durationFn = useRef();
    const [isClosed, setIsClosed] = useState(false);

    const closeBubble = e => {
        if (onClose) {
            onClose(e);
        }
        if (type === "click") {
            setIsClosed(true);
        }
    };

    const hasDurationCloseBubble = useCallback(
        entries => {
            if (entries[0].isIntersecting) {
                setTimeout(() => setIsClosed(true), duration);
            }
        },
        [duration]
    );

    useEffect(() => {
        if (status && duration > 0 && bubbleRef.current) {
            durationFn.current = new window.IntersectionObserver(
                hasDurationCloseBubble
            );
            durationFn.current.observe(bubbleRef.current);
        }
    }, [status, duration]);

    return (
        <BubbleContent
            ref={bubbleRef}
            isShow={status && !isClosed}
            type={type}
            direction={direction}
            vertex={vertex}
            onClose={closeBubble}
            {...rest}
        />
    );
};
export default Bubble;
