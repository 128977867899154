import React from "react";
import cx from "classnames";

import "./IconFont.scss";

const IconFont = ({ className, icon, children, ...rest }) => {
    const processedClassName = cx(className, icon, "rs-icon", "icon");
    return (
        <i className={processedClassName} {...rest}>
            <span>{children}</span>
        </i>
    );
};
export default IconFont;
