import React, { useState } from "react";
import { isMobile } from "../utils/Utils";
import cx from "classnames";
import IconFont from "../IconFont";
import SelectModal from "../SelectModal";
import Radio from "../Radio";

import "./Dropdown.scss";

export const DropdownItem = ({
    isActive,
    disabled,
    setStatus,
    showRadio,
    onClick,
    children
}) => (
    <div
        className={cx("rs-dropdown-item", {
            active: isActive
        })}
        tabIndex={0}
        role="option"
        aria-selected="false"
        onClick={() => {
            if (disabled) return;
            if (onClick) onClick();
            setStatus(false);
        }}
        onKeyDown={e => {
            if (disabled) return;
            if (e.keyCode === 13) {
                if (onClick) onClick();
                setStatus(false);
            } else if (e.keyCode === 27) {
                setStatus(false);
            }
        }}
    >
        {showRadio && isMobile() ? (
            <Radio isActive={isActive}>{children}</Radio>
        ) : (
            children
        )}
    </div>
);

const DropdownTitle = ({ value, status, setStatus, disabled }) => (
    <div
        className="rs-dropdown-title"
        onClick={() => disabled || setStatus(!status)}
        onKeyDown={e => {
            if (e.keyCode === 13 && !disabled) {
                setStatus(!status);
            }
        }}
        role="listbox"
        tabIndex={0}
    >
        <div className="rs-dropdown-title-inner">{value}</div>
        <IconFont icon={status ? "icon_arrow_up" : "icon_arrow_down"}>
            더보기
        </IconFont>
    </div>
);

const Dropdown = ({
    theme = "theme4",
    value,
    defaultValue,
    direction = "bottom",
    showRadio = false,
    onClick,
    disabled,
    mobileShowType = "modal", // ["default", "modal"]
    className,
    children,
    ...rest
}) => {
    const [status, setStatus] = useState(false);

    const returnValue = () => {
        if (value) return value;
        if (children && children.length) {
            const selectedOption = React.Children.toArray(children).find(
                child => child.props && child.props.isActive
            );
            if (selectedOption) return selectedOption.props.children;
        }
        if (defaultValue) return defaultValue;
        if (children && children.length) {
            return children[0].props.children;
        }
        return "";
    };

    return (
        <div
            className={cx("rs-dropdown", className, direction, theme, {
                active: status,
                "no-select-modal": mobileShowType !== "modal",
                disabled
            })}
            {...rest}
        >
            <DropdownTitle
                value={returnValue()}
                status={status}
                setStatus={setStatus}
                disabled={disabled}
            />
            <div className={cx("rs-dropdown-list", { active: status })}>
                {React.Children.map(children, child =>
                    child
                        ? React.cloneElement(child, {
                              setStatus,
                              showRadio,
                              disabled
                          })
                        : null
                )}
            </div>
            {status && (
                <React.Fragment>
                    <div
                        className="rs-dropdown-background"
                        onClick={() => setStatus(false)}
                    />
                    {mobileShowType === "modal" && (
                        <SelectModal
                            status={status}
                            value={value}
                            className={className}
                            setStatus={setStatus}
                            onClick={onClick}
                            {...rest}
                        >
                            {React.Children.map(children, child =>
                                React.cloneElement(child, {
                                    setStatus,
                                    showRadio,
                                    disabled
                                })
                            )}
                        </SelectModal>
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

export default Dropdown;
