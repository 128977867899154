import React from "react";
import cx from "classnames";

import "./InfoBox.scss";

// type = ["line"]
const InfoBox = ({ className, color, type, children, ...rest }) => {
    const colorName = color + (type ? `-${type}` : "");

    return (
        <div className={cx("rs-info-box", className, colorName)} {...rest}>
            {children}
        </div>
    );
};

export default InfoBox;
