import React, { useState } from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Collapse.scss";

export const Panel = ({
    active,
    setActive,
    title,
    prefix = ["", ""],
    className,
    children,
    ...rest
}) => (
    <li className={cx("rs-collapse-item", className, { active })} {...rest}>
        <div
            className="rs-collapse-item-title"
            tabIndex={0}
            role="button"
            onClick={setActive}
            onKeyDown={e => e.keyCode === 13 && setActive()}
            {...rest}
        >
            {prefix && prefix[0] && (
                <span className="rs-collapse-prefix">{prefix[0]}</span>
            )}
            <p className="rs-collapse-item-children">{title}</p>
            <IconFont icon={active ? "icon_arrow_up" : "icon_arrow_down"}>
                더보기
            </IconFont>
        </div>
        <div className="rs-collapse-item-content">
            {prefix && prefix[1] && (
                <span className="rs-collapse-prefix">{prefix[1]}</span>
            )}
            <div className="rs-collapse-item-children">{children}</div>
        </div>
    </li>
);

const Collapse = ({ className, children, ...rest }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onChangeIndex = index => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <ul className={cx("rs-collapse", className)} {...rest}>
            {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                    active: index === activeIndex,
                    setActive: () => onChangeIndex(index)
                })
            )}
        </ul>
    );
};

export default Collapse;
