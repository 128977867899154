import React, { useState, useEffect } from "react";
import cx from "classnames";

import "./Segmented.scss";

export const SegmentedItem = ({
    value,
    current,
    className,
    children,
    ...rest
}) => (
    <div
        className={cx("rs-segmented-item", className, {
            active: current === value
        })}
        {...rest}
    >
        {children}
    </div>
);

const Segmented = ({ current, className, theme = "primary", children }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        React.Children.forEach(children, (child, index) => {
            if (child.props.value === current) {
                setActiveIndex(index);
            }
        });
    }, [children]);

    return (
        <div className={cx("rs-segmented", className, theme)}>
            <div className="rs-segmented-active-background-wrapper">
                <div
                    className="rs-segmented-active-background"
                    style={{
                        width: `${100 / React.Children.count(children)}%`,
                        left: `${(100 / React.Children.count(children)) *
                            activeIndex}%`
                    }}
                />
            </div>
            {React.Children.map(children, child =>
                child
                    ? React.cloneElement(child, {
                          current
                      })
                    : null
            )}
        </div>
    );
};

export default Segmented;
