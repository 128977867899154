import React, { useState, useEffect, forwardRef } from "react";
import cx from "classnames";
import { numberWithCommas } from "../utils/Utils";

import "./Input.scss";

const isNumber = value => {
    return typeof value === "number";
};

const space = /\s/g;
const Input = forwardRef(
    (
        {
            value,
            onChange,
            onBlur,
            min = 0,
            max = 1000000000,
            numberFormat,
            noSpace,
            readOnly,
            className,
            ...rest
        },
        ref
    ) => {
        const [realVal, setRealVal] = useState(value);

        useEffect(() => {
            setRealVal(value);
        }, [value]);

        const returnValue = () => {
            if (numberFormat) {
                if (realVal) return numberWithCommas(realVal);
                if (realVal === null) return "";
                return realVal;
            }
            if (value === null) return "";
            if (value === undefined) return "";
            return value;
        };

        const finalValue = returnValue();

        return (
            <React.Fragment>
                {ref && value === undefined ? (
                    <input
                        ref={ref}
                        className={cx(className, "rs-input")}
                        {...rest}
                    />
                ) : (
                    <input
                        ref={ref}
                        className={cx(className, "rs-input", {
                            "has-value": !!finalValue
                        })}
                        value={finalValue}
                        onChange={e => {
                            let val = e.target.value;
                            if (noSpace) {
                                val = val.replace(space, "");
                            }
                            if (onChange) {
                                if (numberFormat) {
                                    val = val.replace(/(,)/g, "");
                                    if (max && Number(val) > max) {
                                        return onChange(realVal);
                                    }
                                    if (["0", 0].indexOf(val) > -1) {
                                        val = 0;
                                    } else {
                                        val = Number(val) || "";
                                    }
                                }
                                onChange(val);
                                setRealVal(val);
                            }
                        }}
                        readOnly={(realVal && !onChange) || readOnly}
                        onBlur={() => {
                            if (numberFormat) {
                                // infinity이거나 NaN일 때
                                if (
                                    Number(value) === Infinity ||
                                    isNaN(Number(value))
                                ) {
                                    setRealVal(max || 0);
                                    onChange(max || 0);
                                } else if (
                                    isNumber(Number(min)) &&
                                    (Number(value) < min || !realVal)
                                ) {
                                    // min이 정해져있는데 value가 min보다 작거나 value가 없을 때
                                    setRealVal(min);
                                    onChange(min);
                                } else if (
                                    isNumber(Number(max)) &&
                                    value.length > 15
                                ) {
                                    // max가 정해져있는데 15자 초과일 때 (자리수 변경 가능)
                                    setRealVal(max || 0);
                                    onChange(max || 0);
                                } else if (
                                    isNumber(Number(max)) &&
                                    Number(value) > max
                                ) {
                                    // max가 정해져있는데 value가 max보다 클 때
                                    setRealVal(max);
                                    onChange(max);
                                } else {
                                    onChange(Number(value));
                                    setRealVal(Number(value));
                                }
                            } else if (onChange) {
                                onChange(
                                    value && value.replace(/(\b)/g, "").trim()
                                );
                            }
                            return onBlur && onBlur();
                        }}
                        {...rest}
                    />
                )}
            </React.Fragment>
        );
    }
);

export default Input;
