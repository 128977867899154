import React, { useState, useEffect } from "react";
import cx from "classnames";

import "./ModalBackground.scss";

const checkClassName = ".rs-modal-background.active";
const ModalBackground = ({
    status = true,
    onClick,
    transparent,
    noFullHeight,
    className,
    children,
    ...rest
}) => {
    const onHandleClassNameToBody = status => {
        if (status) {
            document.querySelector("body").classList.add("lock-position");
        } else {
            document.querySelector("body").classList.remove("lock-position");
        }
    };

    useEffect(() => {
        if (status) {
            onHandleClassNameToBody(true);
        } else {
            const activeElement = document.querySelector(checkClassName);

            if (!activeElement) {
                onHandleClassNameToBody(false);
            }
        }
    }, [status]);

    useEffect(() => {
        return () => {
            const activeElements = document.querySelectorAll(checkClassName);

            if (activeElements.length <= 1) {
                onHandleClassNameToBody(false);
            }
        };
    }, []);

    return (
        <div
            className={cx("rs-modal-background", className, {
                active: status,
                transparent,
                "no-full-height": noFullHeight
            })}
            {...rest}
        >
            <div
                className={cx("rs-modal-background-element", {
                    "has-click": !!onClick
                })}
                onClick={onClick}
            />
            <div className="rs-modal-background-content">{children}</div>
        </div>
    );
};
export default ModalBackground;
