import React, { useState } from "react";
import cx from "classnames";
import IconFont from "../IconFont";
import { isMobile } from "../utils/Utils";
import StandardModal from "../StandardModal";

import "./MessageBox.scss";

const MessageBox = ({
    title,
    direction = "rightTop",
    modalTitle,
    modalPreWrap,
    className,
    children,
    ...rest
}) => {
    const processedClassName = cx("rs-message-box", className);
    const [modalStatus, setModalStatus] = useState(false);

    return (
        <div
            className={processedClassName}
            onClick={() => {
                if (isMobile()) {
                    setModalStatus(true);
                }
            }}
            {...rest}
        >
            <div className="rs-message-box-title">{title}</div>
            <div className="rs-message-box-icon">
                <IconFont icon="icon_info">설명</IconFont>
                <div className={cx("rs-message-box-content", direction)}>
                    {children}
                </div>
            </div>
            {modalStatus && (
                <StandardModal
                    id="rs-message-box-content-modal"
                    className={cx({ "pre-wrap": modalPreWrap })}
                    modalTitle={modalTitle || title}
                    align="center"
                    closeModal={() => setModalStatus(false)}
                >
                    {children}
                </StandardModal>
            )}
        </div>
    );
};

export default MessageBox;
