import React, { useState } from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import IconFont from "../IconFont";

import "./FunctionalModal.scss";

// 새로 사용되지 않는 컴포넌트
const FunctionalModal = ({
    className,
    closeModal,
    modalTitle,
    modalFooter,
    children,
    ...rest
}) => {
    const [status, setStatus] = useState(true);
    const clickCloseBtn = () => {
        if (closeModal) {
            setStatus(false);
            setTimeout(() => closeModal(), 100);
        }
    };

    return (
        <ModalWrapper>
            <ModalBackground>
                <div
                    className={cx("rs-functional-modal", className, {
                        active: status,
                        "has-footer": !!modalFooter
                    })}
                    {...rest}
                >
                    <div className="rs-functional-modal-title">
                        <IconFont icon="icon_logo_symbol">로고</IconFont>
                        {modalTitle}
                        <div
                            className="rs-functional-modal-close-btn"
                            onClick={() => clickCloseBtn()}
                            onKeyDown={e => e.keyCode === 13 && clickCloseBtn()}
                            tabIndex={0}
                            role="button"
                        >
                            <IconFont icon="icon_close" />
                        </div>
                    </div>

                    <div className="rs-functional-modal-scroll-area">
                        <div className="rs-functional-modal-content">
                            {children}
                        </div>
                    </div>

                    <div
                        className={cx("rs-functional-modal-footer", {
                            "has-content": !!modalFooter
                        })}
                    >
                        {modalFooter}
                    </div>
                </div>
            </ModalBackground>
        </ModalWrapper>
    );
};

export default FunctionalModal;
