import React from "react";
import cx from "classnames";

import "./Switch.scss";

const Switch = ({ status, className, ...rest }) => (
    <button
        type="button"
        className={cx("rs-switch", className, { active: !!status })}
        {...rest}
    >
        <div className="rs-switch-handle" />
    </button>
);

export default Switch;
