import React, { useState } from "react";
import cx from "classnames";
import range from "lodash/range";
import { isMobile } from "../utils/Utils";
import IconFont from "../IconFont";

import "./Pagination.scss";

const Pagination = ({ start = 1, end, value = start, className, onClick }) => {
    const count = isMobile() ? 5 : 10;

    const [index, setIndex] = useState(Math.floor(value / count));
    const list = range(start, end + 1, 1);
    const maxIndex =
        end % count ? Math.floor(end / count) : Math.floor(end / count) - 1;

    return (
        <div className={cx("rs-pagination", className)}>
            <div className="rs-pagination-items">
                {index ? (
                    <IconFont
                        icon="icon_arrow_left"
                        onClick={() => (index ? setIndex(index - 1) : false)}
                    >
                        이전
                    </IconFont>
                ) : (
                    false
                )}
                {list.map((val, listIndex) => {
                    if (
                        index * count <= listIndex &&
                        listIndex < (index + 1) * count
                    ) {
                        return (
                            <div
                                className={
                                    value + 1 === val
                                        ? "rs-pagination-item active"
                                        : "rs-pagination-item"
                                }
                                key={listIndex}
                                onClick={() => onClick(val - 1)}
                            >
                                {val}
                            </div>
                        );
                    }
                    return null;
                })}
                {index < maxIndex ? (
                    <IconFont
                        icon="icon_arrow_right"
                        onClick={() => setIndex(index + 1)}
                    >
                        다음
                    </IconFont>
                ) : (
                    false
                )}
            </div>
        </div>
    );
};

export default Pagination;
