import React from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";

import "./NormalModal.scss";

const NormalModal = ({ className, children, onClickBackground, ...rest }) => (
    <ModalWrapper>
        <ModalBackground onClick={onClickBackground}>
            <div className={cx("rs-normal-modal", className)} {...rest}>
                {children}
            </div>
        </ModalBackground>
    </ModalWrapper>
);
export default NormalModal;
