import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Steps.scss";

export const Step = ({
    index,
    current,
    status,
    currentStatus,
    title,
    description,
    className,
    ...rest
}) => {
    //간단하게 변경 필요
    const _status =
        current < index
            ? "none"
            : current === index
            ? status || currentStatus
            : current > index
            ? status || "finish"
            : "none";

    return (
        <React.Fragment>
            {index > 0 && (
                <IconFont icon="icon_arrow_right" className="rs-step-next-icon">
                    다음
                </IconFont>
            )}
            <div
                className={cx("rs-step-item", _status, className, {
                    current: current === index
                })}
                {...rest}
            >
                <div className="rs-step-index-box">
                    <div className="rs-step-index">
                        {_status === "finish" ? (
                            <IconFont icon="icon_check">완료</IconFont>
                        ) : _status === "error" ? (
                            <IconFont icon="icon_close">에러</IconFont>
                        ) : (
                            index + 1
                        )}
                    </div>
                    <div className="rs-step-connection" />
                </div>
                <div className="rs-step-text">
                    <p className="rs-step-title">{title}</p>
                    {description && (
                        <p className="rs-step-desc">{description}</p>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

const Steps = ({
    type = "row", //[row, column],
    current = 0,
    status = "none",
    className,
    children,
    ...rest
}) => (
    <div className={cx("rs-steps", type, className)} {...rest}>
        {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
                index,
                current,
                currentStatus: current === index ? status : "none"
            })
        )}
    </div>
);

export default Steps;
