import React from "react";
import cx from "classnames";

import grade_dia from "../assets/images/grade/grade_dia.png";
import grade_gold from "../assets/images/grade/grade_gold.png";
import grade_silver from "../assets/images/grade/grade_silver.png";
import grade_white from "../assets/images/grade/grade_white.svg";
import profile_default from "../assets/images/grade/profile_default.svg";

import "./Profile.scss";

const returnGradeImage = grade => {
    switch (grade) {
        case "a":
            return grade_white;
        case "b":
            return grade_silver;
        case "c":
            return grade_gold;
        case "d":
            return grade_dia;
        default:
            "";
    }
};

const Profile = ({
    grade,
    blackUser,
    img,
    alt,
    noBand,
    skeleton,
    connected,
    className,
    ...rest
}) => (
    <div
        className={cx(
            "rs-profile",
            `grade-${grade}`,
            { "no-band": noBand },
            className
        )}
        {...rest}
    >
        <div
            className="rs-grade-area"
            style={{
                backgroundImage:
                    noBand || blackUser || `url(${returnGradeImage(grade)})`
            }}
        >
            {skeleton ? (
                <div className="rs-grade-image skeleton" />
            ) : (
                <img
                    src={img || profile_default}
                    alt={alt || "프로필 이미지"}
                    className="rs-grade-image"
                />
            )}
            {connected && <div className="rs-connected-mark" />}
        </div>
    </div>
);
export default Profile;
