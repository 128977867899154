export const isMobile = () => {
    return window.innerWidth < 768;
};

export const numberWithCommas = value => {
    if (value) {
        if (typeof value === "string") {
            return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else if (typeof value === "number") {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    return 0;
};
export function convertTypeToKr(type) {
    switch (type) {
        case "NaverBlog":
            return "네이버블로그";
        case "Instagram":
            return "인스타그램";
        case "NaverPost":
            return "네이버포스트";
        case "NaverCafe":
            return "카페";
        case "Facebook":
            return "페이스북";
        case "KakaoStory":
            return "카카오스토리";
        case "Youtube":
            return "유튜브";
        case "AppReview":
            return "앱리뷰";
        case "SelfMarket":
            return "자사몰";
        case "OnlineComm":
            return "쇼핑몰";
        case "OtherSns":
            return "기타";
        case "Delivery":
            return "배송형";
        case "Visit":
            return "방문형";
        case "Buy":
            return "구매형";
        case "Reporters":
            return "기자단";
        default:
            return type;
    }
}

export const convertTypeToEng = type => {
    switch (type) {
        case "네이버블로그":
            return "NaverBlog";
        case "네이버카페":
            return "NaverCafe";
        case "카페":
            return "NaverCafe";
        case "네이버포스트":
            return "NaverPost";
        case "페이스북":
            return "Facebook";
        case "인스타그램":
            return "Instagram";
        case "카카오스토리":
            return "KakaoStory";
        case "유튜브":
            return "Youtube";
        case "앱리뷰":
            return "AppReview";
        case "자사몰":
            return "SelfMarket";
        case "쇼핑몰":
            return "OnlineComm";
        case "기타":
            return "OtherSns";
        case "배송형":
            return "Delivery";
        case "방문형":
            return "Visit";
        case "구매형":
            return "Buy";
        case "기자단":
            return "Reporters";
        default:
            return type;
    }
};

export const convertCategory = val => {
    switch (val) {
        case "cosmetic":
            return "코스메틱";
        case "beauty":
            return "미용";
        case "fashion":
            return "패션/잡화";
        case "food":
            return "식품";
        case "supplies":
            return "생활용품";
        case "baby":
            return "출산/육아";
        case "digital":
            return "디지털/가전";
        case "sports":
            return "스포츠/건강";
        case "pet":
            return "반려동물";
        case "restaurant":
            return "맛집";
        case "travel":
            return "여행/숙박";
        case "area":
            return "지역/문화";
        case "etc":
            return "기타";
        default:
            return val;
    }
};

export const convertSuggestStatus = (status, isReviewer) => {
    switch (status) {
        case "Waiting":
            return "수락 대기 중";
        case "Running":
            return "진행 중";
        case "Submitted":
            return isReviewer ? "리뷰 검토 중" : "리뷰 제출 완료";
        case "Rejected":
            return "거절";
        case "NoResponse":
            return "무응답 취소";
        case "Refunded":
            return "환급완료";
        case "Complete":
            return "종료";
        case "Canceled":
            return "제안 취소";
        case "Issued":
            return "포인트 지급 완료";
        default:
            return status;
    }
};
