import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import icon_checked_disabled from "../assets/images/icon/icon_checked_disabled.svg";
import icon_unchecked_disabled from "../assets/images/icon/icon_unchecked_disabled.svg";

import "./Checkbox.scss";

const Checkbox = ({
    className,
    status,
    primary,
    onClick,
    hasLink,
    children,
    disabled,
    ...rest
}) => {
    const processedClassName = cx("rs-checkbox", className, { disabled });
    return (
        <div
            className={processedClassName}
            onClick={e => !disabled && !hasLink && onClick(e)}
            tabIndex={0}
            onKeyDown={e =>
                e.keyCode === 13 && !disabled && !hasLink && onClick(e)
            }
            {...rest}
        >
            {disabled ? (
                <img
                    className="rs-icon"
                    src={
                        status ? icon_checked_disabled : icon_unchecked_disabled
                    }
                    alt="비활성화"
                />
            ) : (
                <IconFont
                    icon={
                        status
                            ? primary
                                ? "icon_checkbox_fill"
                                : "icon_checkbox_on"
                            : "icon_checkbox_off"
                    }
                    onClick={() => hasLink && onClick()}
                />
            )}
            <div className="rs-checkbox-text">{children}</div>
        </div>
    );
};
export default Checkbox;
