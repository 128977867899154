import React, { useEffect } from "react";
import cx from "classnames";
import ModalWrapper from "../ModalWrapper";
import ModalBackground from "../ModalBackground";
import Button from "../Button";

import "./AlertModal.scss";

const AlertModal = ({
    confirmText,
    handleModal,
    className,
    children,
    ...rest
}) => {
    useEffect(() => {
        const btn = document.getElementById("rs-alert-modal-confirm-btn");
        if (btn) {
            btn.focus();
        }
    }, []);

    return (
        <ModalWrapper>
            <ModalBackground>
                <div className={cx("rs-alert-modal", className)} {...rest}>
                    <div className="rs-alert-modal-content">{children}</div>
                    <Button
                        className="rs-alert-modal-confirm-btn clear size-l m-size-s"
                        type="button"
                        onClick={handleModal}
                        id="rs-alert-modal-confirm-btn"
                    >
                        {confirmText || "확인"}
                    </Button>
                </div>
            </ModalBackground>
        </ModalWrapper>
    );
};

export default AlertModal;
