import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Spin.scss";

const Spin = ({ className }) => (
    <div className={cx("rs-spin", className)}>
        <div className="rs-spin-rotate-area">
            <IconFont icon="icon_loading">로딩중</IconFont>
        </div>
    </div>
);

export default Spin;
