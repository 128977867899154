import React from "react";
import cx from "classnames";
import IconFont from "../IconFont";

import "./Tag.scss";

// type: ["line, fill"]
// size: "default: size-s, m-size-s"
const Tag = ({ type = "fill", color, empty, className, children, ...rest }) => {
    const colorName = color ? `${color}-${type}` : "default";

    return (
        <span
            className={cx("rs-tag", colorName, className, { empty })}
            {...rest}
        >
            {children}
        </span>
    );
};
export default Tag;
